:root {
  --color-background-light: #ffbb00;
  --color-background-dark: #262626;
  --color-text-dark: #ffbb00;
  --color-text-light: #000000;
  --color-border: #ffd92a;

  --color-disabled: #000;
  --color-alert: #fff;
}

html, body {
  background-color: var(--color-background-dark);
  color: #fff;
}

.container {
  font-family: Arial, Helvetica, sans-serif;
}

div:focus {
  outline: none;  
}

.App {
  text-align: center;
}

.App-header {
  background-color: var(--color-background-light);
  color: var(--color-text-light);
  text-align: center;
  margin-bottom: 10px;
}

.App-footer {
  font-size: smaller;
  max-width: 400px;
  margin: 40px auto;
  overflow: auto;  
  text-align: center;
}

.App-footer .logo {
  width: 50%;
}

.App-home-footer {
  font-size: smaller;
  margin-top: 100px;
  text-align: center;
}

a {
  border-bottom: 2px solid var(--color-border);
  color: var(--color-text-dark);
  text-decoration: none;
}

a.noLine {
  border-bottom: none;
}

.container {
  max-width: 400px;
  margin: 0 auto;
  overflow: auto;
  min-height: 300px;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.btnTiny {
  font-size: 9px;
  padding: 0.6rem 0.7rem;
}

.actionBar {
  display: flex;
  flex-flow: row nowrap;
}

.actionBar > * {
  flex: 1 10%;
}

.actionBar .btnShort {
  font-size: 1em;
  text-align: center;
}

.actionBar .btnSubmit {
  background-color: var(--color-background-light);
  color: var(--color-text-light);
  font-size: 1em;
  margin: 5px 1em;
  height: 3em;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.actionBar .btnFlex {
  flex: 3 80%;
}

.actionBar .btnSubmit b {
  display: inline-block;
  width: 15%;
}

.actionBar .btnSubmit:disabled {
  background-color: var(--color-disabled);
  color: var(--color-text-dark);
}

.footer {
  font-size: small;
}

.marginTop {
  margin-top: 10px;
}

dd {
  text-align: justify;
}