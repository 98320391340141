@keyframes shrink {
  from {
      transform:scale(1.5);
  }
  to {
      transform:scale(1);
  }
}

.guessContainer {
  overflow: hidden;
  padding: 0 5px;
}

.guesses {
  box-sizing: border-box;
}

.guess {
  background-color: var(--color-background-light);
  /*border: 1px solid var(--color-background-light);*/
  border-left: none;
  border-right: none;
  box-sizing: border-box;  
  color: var(--color-text-light);
  display: flex;
  flex-flow: row wrap;
  font-size: 2em;;
  margin: 0 0 10px;
}

.guess div {
  aspect-ratio: 1;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 20%;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  animation-name: shrink;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.guess.incorrect {
  color: black;
}
