.alert {
  background-color: var(--color-alert);
  border-radius: 5px;
  box-sizing: border-box;  
  color: var(--color-background-dark);
  display: block;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  padding: 8px 16px;
  position: fixed;
  opacity: 0;
  top: 25%;
  z-index: 100000;
  text-align: center;
  width: 100px;
	animation-duration: 4s;
	animation-name: fadeOut;
}

.alert-long {
  width: 200px;
}

@keyframes fadeOut {
	0% {
		background-color: var(--color-alert);
    opacity: 1;
	}

	33% {
		background-color: var(--color-alert);
    opacity: 1;
	}

  100% {
		background-color: transparent;
    opacity: 0;
	}
}
