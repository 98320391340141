.grid {
  display: flex;
  flex-flow: row wrap;
}

.column {
  box-sizing: border-box;
  flex: 1 1 20%;
  flex-flow: column;
  padding: 0 5px;
}

.column:hover .letter {
  color: white;
}

.grid-disabled .column:hover {
  background-color: transparent;
}
