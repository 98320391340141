.header-container {
  max-width: 400px;
  margin: 0 auto;
}

header {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  padding: 5px;
}

header h1 {
  flex: 3 1 60%;
  flex-flow: column;
  font-size: 22px;
  margin: 0;
  text-align: left;
}

header .btn {
  background-color: transparent;
  color: #000;
  font-size: 22px;
  margin: 0 10px;
  padding: 0 0 0 10px
}