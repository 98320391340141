.letter {
  aspect-ratio: 1;
  background-color: transparent;
  border: 1px solid var(--color-border);
  box-sizing: content-box;
  color: var(--color-text-dark);
  cursor: pointer;
  display: block;
  font-size: 2em;
  margin: 0 0 10px;
  padding: 0;
  text-align: center;
  width: 100%;
}

.letter.active {
  background-color: var(--color-border);
  color: var(--color-text-light) !important;
}

.grid-disabled .letter {
  background-color: var(--color-disabled);
  border-color: var(--color-disabled);
  color: #fff;
}
