.modalScore h2 {
  text-align: center;
}

.summary {
  display: flex;
  flex-wrap: wrap;
}

.summary > div {
  flex: 1 1 50%;
  flex-grow: 1;
  margin-bottom: 10px;
}

.summary > div span {
  display: block;
  text-align: center;
}

.score {
  margin: 0;
  padding: 0;
}

.score li {
  display: block;
}

.score .key {
  display: inline-block;
  width: 10%;
}

.score .value {
  display: inline-block;
  height: 10px;
  width: 90%;
}

.score .value div {
  background-color: var(--color-background-light);
  box-sizing: border-box;
  color: var(--color-text-light);
  font-size: 0.8em;
  font-weight: bold;
  text-align: right;
  padding: 1px 2px; 
}

.score .current .value div {
  background-color: var(--color-border);
}

.center {
  text-align: center;
}