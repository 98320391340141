.modal-container {
  background-color: var(--color-background-dark);
}

#modal-modal-title {
  position: relative;
}

#modal-modal-title div {
  text-align: center;
}

#modal-modal-title button {
  background-color: transparent;
  border: none;
  color: var(--color-text-dark);
  font-size: 1.4em;
  position: absolute;
  top: 0;
  right: 0;
}

#modal-modal-title button:hover {
  color: white;
}